

.modal__content {
    display: flex;
    justify-content: center;
}
.modal__box{   
    text-align: center;
    position: relative;
    width: 100%;
    max-width: 600px;   
    background-color: rgb(193, 223, 223);
    display: flex;
    flex-direction: column;
    padding: 20px;

}
.modal__title {
        font-size: 40px;
}
.modal__button {
    position: absolute;
    right: 25px;
}
.modal__label {
    text-align: left;
    margin: 0px 0px 10px 0px;
    font-size: 20px;
    font-weight: 700;
    color: rgb(20, 8, 8);
    
}
.modal__input {
    min-height: 20px;
    font-size: 16px;
    margin: 0px 0px 20px 0px;
    padding: 10px ;
}
.modal__btn_box{
        display: flex;
        justify-content: space-between;
}

.modal__btn {
    background: white;
    border: 2px solid teal;
    padding: 10px 0px 10px 0px;
    cursor: pointer;
    flex-basis: 45%;
    font-size: 20px;
    font-weight: 700;
    color: rgb(20, 8, 8);
    transition: .3s;
}

.modal__btn:hover {
    background: teal;
    color: rgb(253, 253, 253);

}