.box {
    width: 400px;
    margin: auto;
}
.form {
    max-width: 400px;
}
 .form__content {
    display: flex;
    flex-direction: column;
 }

 .form__label {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    margin: 0px 0px 20px 0px;
 }

 .form__input {
    width: 100%;
    height: 30px;
    box-sizing: border-box;
    flex-basis: 80%;
    border: 2px solid rgb(41, 180, 41);
    padding: 10px;
 }
 .form__button {
    display: block;
    width: 100%;
    max-width: 150px;
    margin-left: auto;
    padding: 10px;
    background-color: transparent;
    border: 2px solid rgb(41, 180, 41);
    cursor: pointer;
    font-size: 19px;
}
.error {
   margin: 0px 0px 15px 0px;
   text-transform: uppercase;
}
