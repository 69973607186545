.header {
    margin: 0px 0px 60px 0px;
}
.container {
    max-width: 1380px;
    padding: 0px 15px 0px 15px;
    margin: auto;

}
.nav {
    max-width: 70%;
    height: 60px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.link {
    text-decoration: none;
    color: black;
    font-size: 18px;
}
.header__button {
    display: block;
    width: 100%;
    max-width: 100px;
    padding: 10px 5px;
    background-color: transparent;
    border: 1px solid lavender;
    cursor: pointer;
    border-radius: 15px;
    font-size: 15px;
    transition: .2s;
}
.header__button:hover {
    background-color: lavender;

}