:root {
    --select-border-color: rgb(41, 180, 41);
}
.box {
    display: flex;
    gap: 15px;
}
.sort {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid var(--select-border-color);
    
}
.button {
    border: 1px solid var(--select-border-color);
    background-color: transparent;
    cursor: pointer;
    border-radius: 5px;
    transition: .2s;

}
.button:hover {
    
    background: teal;
    color: rgb(253, 253, 253);
}