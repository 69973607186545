html ,body {
  position: relative;
}
*{
  font-size: 16px;
}
.App {
  text-align: center;
  height: 100%;
  position: relative;
  font-size: 16px;
}
.App__box {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

}
