:root {
    --table-border-color: rgba(213, 218, 218, .5);
}
.list {
    position: relative;
    z-index: 1;
}
.list__button {
    display: block;
    width: 100%;
    max-width: 250px;    
    margin: 0px auto 15px auto;
    padding: 10px;
    background-color: transparent;
    border: 1px solid rgb(41, 180, 41);
    cursor: pointer;
    font-size: 19px;
    font-weight: 700;
    transition: .3s;

}
.list__button:hover {
    background: teal;
    color: rgb(253, 253, 253);

}
.list__button__box {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 5px;
}
.list__button_size {
    max-width: 150px;
    padding: 5px;
    margin: 0px auto 0px auto;

}
.list__box {
   
    display: grid;
    grid-template-columns: 1539px 135px; 
    justify-content: space-between;
    border: 2px solid var(--table-border-color);
    margin: 0px 0px 15px 0px;
    align-items: center;
    font-size: 19px;
    font-weight: 400;
    
}
.list__content {
    display: grid;
    grid-template-columns: 90px 150px 175px 180px 135px 135px 135px 135px 135px 135px 135px;

}
.list__item {
    text-align: left;
    text-align: center;
    padding: 4px;
}

.list__item_one {
    border-right: 1px solid var(--table-border-color);
    flex-basis: 100px;

}
.list__item_two  {
    border-right: 1px solid var(--table-border-color);
    flex-basis: 160px;
    min-height: 30px;

}
.list__item_three {
    border-right: 1px solid var(--table-border-color);
    flex-basis: 200px;

}
.list__item_four {
    border-right: 1px solid var(--table-border-color);
    flex-basis: 200px;

}
.list__item_five {
    border-right: 1px solid var(--table-border-color);
    flex-basis: 150px;

}
.list__item_six {
    flex-basis: 150px;
    border-right: 1px solid var(--table-border-color);
}

.list__item_seven {
    border-right: 1px solid var(--table-border-color);
    flex-basis: 150px;

}
.list__item_eight {
    border-right: 1px solid var(--table-border-color);
    flex-basis: 150px;
}
.list__item_nine {
    border-right: 1px solid var(--table-border-color);
    flex-basis: 150px;
}
.list__item_ten{
    flex-basis: 150px;
    border-right: 1px solid var(--table-border-color);
}
.list__item_eleven{
    flex-basis: 150px;

}

.update__box {
    position:absolute;
    display: flex;
    top: -20px;
    /* display: none; */
}