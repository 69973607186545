

.modal__wrapper {
    display: flex;
    justify-content: center;
}
.modal__box{   
    text-align: center;
    position: relative;
    width: 100%;
    max-width: 800px;   
    background-color: rgb(193, 223, 223);
    display: flex;
    flex-direction: column;
    padding: 20px;

}
.madal__content {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}
.modal__left {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;

}
.modal__right {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
}
.modal__title {
    font-size: 30px;
    line-height: 0;
    margin: 10px 0px 30px 0px;
}
.modal__button {
    position: absolute;
    right: 25px;
}
.modal__label {
    text-align: left;
    margin: 0px 0px 10px 0px;
    font-size: 17px;
    font-weight: 700;
    color: rgb(20, 8, 8);
    
}
.modal__input {
    min-height: 30px;
    font-size: 20px;
    margin: 0px 0px 15px 0px;
    padding: 10px ;
}
.modal__btn_box{
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.modal__btn {
    background: white;
    border: 2px solid teal;
    padding: 10px 0px 10px 0px;
    cursor: pointer;
    flex-basis: 50%;
    font-size: 18px;
    font-weight: 700;
    color: rgb(20, 8, 8);
    transition: .3s;
}

.modal__btn:hover {
    background: teal;
    color: rgb(253, 253, 253);

}
.modal__select {
    margin-top: 30px;
}