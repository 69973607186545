.list__column__names {
    list-style-type: none;
    display: grid;
    grid-template-columns: 90px 150px 175px 180px 135px 135px 135px 135px 135px 135px 135px;
    max-width: 1539px;
    align-items: center;
}
.list__column__names__item {
    display: block;
    text-align: center;
    min-height: 20px;
    font-size: 13px;
    font-weight: 700;
}
.list__column__names__item_one {
    flex-basis: 100px;



}
.list__column__names__item_two {
    flex-basis: 160px;


}
.list__column__names__item_three {
    flex-basis: 200px;

}
.list__column__names__item_four {
    flex-basis: 200px;
}
.list__column__names__item_five {
    flex-basis: 150px;

}
.list__column__names__item_six {
    flex-basis: 300px;
}
.list__column__names__item_seven{
    flex-basis: 202px;

}
.list__column__names__item_eight {
    flex-basis: 202px;

}
.list__column__names__item_nine{

    flex-basis: 202px;
}
.list__column__names__item_ten {
    flex-basis: 202px;

}
.list__column__names__item_eleven{
    flex-basis: 202px;

}
.list {
    position: relative;
    z-index: 1;
}
.list__button {
    display: block;
    width: 100%;
    max-width: 250px;    
    margin: 0px auto 15px auto;
    padding: 10px;
    background-color: transparent;
    border: 1px solid rgb(41, 180, 41);
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    transition: .3s;

}